
 import { defineComponent, computed, onMounted } from "vue";
 import { useStore } from "vuex";
import { ref } from "vue";
import ApiService from "@/core/services/ApiService";
import moment from "moment";
import MarkdownIt from "markdown-it";

const md = new MarkdownIt();
 
 export default defineComponent({
     name: "quimerax-news",
     components: {
        
     },
     setup() {
       const store = useStore();
       const currentLanguage = computed(() => store.getters.getCurrentLanguage || localStorage.getItem('currentLanguage'));
        
       const user = computed(() => {
             return store.getters.currentUser;
         });
        const isLoading = ref(false);
        const search = ref('');
        const results = ref([]);
        const responseTime = ref(0);
        // parse the query string
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const q = urlParams.get('q') ?? '';
        const current_page = ref(1);
        const articles = ref([]);
        const lastResultSize = ref(0);
        search.value = q;

        const searchArticles = async (refresh) => {
            isLoading.value = true;
            if(search.value){
                if(refresh){
                    current_page.value = 1;
                }
                // set current history state to the search query
                window.history.pushState({}, '', '/apps/quimereax-news?q=' + search.value);

                const response = ApiService.get('/quimerax-news-search?q='+ search.value +'&page=' + current_page.value);
                const data = await response;
                if(refresh){
                    articles.value = data?.data?.articles;
                    lastResultSize.value = data?.data?.articles.length;
                }else{
                    articles.value = articles.value.concat(data?.data?.articles);
                    lastResultSize.value = data?.data?.articles.length;
                }
                
                
                setTimeout(() => {
                    isLoading.value = false;
                }, 1000);
            }else{
                // push the current history state to the root
                window.history.pushState({}, '', '/apps/quimereax-news');
                const response = ApiService.get('/quimerax-news?page=' + current_page.value);
                const data = await response;

                if(refresh){
                    articles.value = data?.data?.articles;
                    lastResultSize.value = data?.data?.articles.length;
                }else{
                    articles.value = articles.value.concat(data?.data?.articles);
                    lastResultSize.value = data?.data?.articles.length;
                }
                
                setTimeout(() => {
                    isLoading.value = false;
                }, 1000);
            }

            
        }

        searchArticles(true);

        const next_page = () => {
            current_page.value += 1;
            if(lastResultSize.value > 0){
                searchArticles(false);
            }
        }

     return {
         user,
         store,
         currentLanguage,
            search,
            results,
            responseTime,
            articles,
            moment,
            md,
            next_page,
            isLoading,
            searchArticles
     };
     },
     methods: {
       
     },
     mounted() {
        window.addEventListener("scroll", () => {
               
        
               if ((window.innerHeight + window.scrollY) >= document.body.scrollHeight) {
                   if(!this.isLoading){
                   
                       this.next_page();
                   }
               }
           });
     }
 });
 